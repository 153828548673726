<template>
  <div class="Dealership">
    <!-- Dealership -->
    <div class="accordion useredit" id="accordionExample30">
      <div class="card">
        <div class="card-header" id="headingOne30">
          <h2 class="mb-0">
            <button
              class="btn btn-link"
              type="button"
              data-toggle="collapse"
              data-target="#collapseOne30"
              aria-expanded="true"
              aria-controls="collapseOne30"
            >
              <div class="detais-title">
                <h3>
                  <img
                    class="img-fluid arrow-down"
                    src="../../assets/img/arrow-down.svg"
                    alt=""
                  />Personal Infomation
                </h3>
              </div>
            </button>

            <div class="adddetais editarea">
              <!-- <a class="add-appoi" href="#"><img @click="editProfile" class="img-fluid" src="../../assets/img/pencil.svg" alt="" /></a> -->
              <a
                @click="editProfile"
                class="add-appoi"
                href="#"
                data-dismiss="modal"
                aria-label="Close"
                data-toggle="modal"
                data-target="#exampleModal315"
              >
                Update</a
              >
            </div>
          </h2>
        </div>

        <div
          id="collapseOne30"
          class="collapse show"
          aria-labelledby="headingOne30"
          data-parent="#accordionExample30"
          style=""
        >
          <div class="card-body">
            <div class="user-pro">
              <div class="user-setting">
                <div class="user-img" v-if="!doCrop">
                  <div class="button-wrapper">
                    <span class="label">
                      <template v-if="cropImgUrl">
                        <img
                          style="width: 40px; height: 40px"
                          class="img-fluid"
                          :src="cropImgUrl"
                          alt=""
                        />
                      </template>
                      <template v-else-if="profile.picture">
                        <img
                          style="width: 40px; height: 40px"
                          class="img-fluid"
                          :src="profile.picture"
                          alt=""
                        />
                        <!-- <img class="img-fluid" :src="require(profile.picture)" alt="" /> -->
                      </template>
                      <template v-else>
                        <img
                          v-if="!doCrop"
                          class="img-fluid"
                          :src="imgUrl"
                          alt=""
                        />
                      </template>
                    </span>
                    <div class="button-wrapper change">
                      <span class="label"> Change photo </span>
                    </div>
                    <input
                      type="file"
                      ref="file"
                      accept="image/*"
                      v-on:change="handleFileUpload"
                      class="upload upload-box"
                      placeholder="Upload File"
                    />
                  </div>
                </div>
                <div v-if="doCrop">
                  <vue-cropper
                    style="max-height: 300px"
                    v-show="doCrop"
                    :aspect-ratio="1 / 1"
                    :view-mode="1"
                    ref="cropper"
                    :src="cropImgUrl"
                    alt="Source Image"
                  ></vue-cropper
                  ><br />
                  <a
                    href="#"
                    v-on:click="doCrop = false"
                    class="add-btn pull-left"
                    >Cancel</a
                  >
                  <a href="#" v-on:click="cropIt" class="rem-btn pull-right"
                    >Crop</a
                  >
                </div>
              </div>

              <div class="setting-tables">
                <form @submit.prevent="editProfile">
                  <table class="table">
                    <tbody>
                      <tr>
                        <td>First Name</td>
                        <td>
                          <input
                            type="text"
                            v-model="profile.c_first_name"
                            class="form-control"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Last Name</td>
                        <td>
                          <input
                            type="text"
                            v-model="profile.c_last_name"
                            class="form-control"
                          />
                        </td>
                      </tr>

                      <tr>
                        <td>Phone</td>
                        <td>
                          <input
                            type="tel"
                            v-model="profile.c_phone"
                            class="form-control"
                          />
                        </td>
                      </tr>
                      <!-- <tr>
                                                <td>Address</td>
                                                <td>
                                                    <input type="text" v-model="profile.address" class="form-control" />
                                                </td>
                                            </tr> -->
                      <tr>
                        <td></td>
                        <td>
                          <a
                            class="change-pass"
                            href="#"
                            data-dismiss="modal"
                            aria-label="Close"
                            data-toggle="modal"
                            data-target="#exampleModal31"
                            >Change password</a
                          >
                          <router-link
                            :to="{ name: 'weeklySchedule' }"
                            class="change-pass ml-3 pull-right"
                            >Manage Working Hours</router-link
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="exampleModal31"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Change password</h5>
            <a class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>
          <div class="modal-body">
            <div class="add-member">
              <form action="">
                <div class="form-group">
                  <label for="inputAddress">Old Password</label>
                  <input
                    type="password"
                    v-model="profile.password_.oldPassword"
                    class="form-control"
                    placeholder="........"
                  />
                </div>
                <div class="form-group">
                  <label for="inputAddress">New Password</label>
                  <input
                    type="password"
                    v-model="profile.password_.password"
                    class="form-control"
                    placeholder="........"
                  />
                </div>
                <div class="form-group">
                  <label for="inputAddress">New Password</label>
                  <input
                    type="password"
                    v-model="profile.password_.cPassword"
                    class="form-control"
                    placeholder="........"
                  />
                </div>
              </form>
            </div>
          </div>
          <div class="modal-footer">
            <a href="#" class="add-btn"> Cancel</a>
            <a href="#" @click="changePassword" class="rem-btn"> Okay</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>




<script>
import axios from "axios";
import $ from "jquery";
import VueCropper from "vue-cropperjs";
export default {
  name: "Profile",
  components: { VueCropper },
  data() {
    return {
      profile: {
        c_first_name: "",
        c_last_name: "",
        c_phone: "",
        picture: "",
        password_: {},
        address: "",
        img: "",
      },
      imgUrl: require("../../assets/img/profile.svg"),
      cropImgUrl: null,
      doCrop: false,
    };
  },
  created() {
    this.getProfile();
  },
  methods: {
    changePassword() {
      axios
        .post("/update_password", this.profile.password_, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            $("#exampleModal31").modal("hide");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    editProfile() {
      axios
        .post("/edituser", this.profile, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.$toast.open({
              position: "top-right",
              message: "Profile Updated",
            });
            this.$root.$emit("action", "changeprofileimage", res.data.file);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getProfile() {
      axios
        .get("user", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          this.profile.c_first_name = res.data.first_name;
          this.profile.c_last_name = res.data.last_name;
          this.profile.c_phone = res.data.phone;
          this.profile.picture = res.data.picture;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleFileUpload(e) {
      const file = e.target.files[0];
      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.cropImgUrl = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
      this.doCrop = true;

      //this.imgUrl = URL.createObjectURL(this.$refs.file.files[0]);
    },
    cropIt() {
      this.cropImgUrl = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.profile.picture = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.profile.img = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.doCrop = false;
    },
  },
};
</script>
<style scoped>
</style>