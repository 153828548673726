<template>
    <DashboardContainer>
        <div class="setting-area">
            <profile/>
            <!-- <smtp/> -->
            <email-signature/>
        </div>
    </DashboardContainer>
</template>

<script>
import DashboardContainer from '../containers/DashboardContainer'
import Profile from '../components/profile/profile'
import emailSignature from '../components/profile/emailSignature'
// import Smtp from '../components/profile/smtp'

export default {
    //name:'editProfile',
    beforeCreate: function () {
        var auth = this.$storage.get('auth')
        if (!auth) {
            this.$router.push('/login')
        }else{
            if(!auth.token){
                this.$router.push('/login')
            }
        }
    },
    components: {
        DashboardContainer,
        Profile,
        emailSignature
    }
}
</script>
